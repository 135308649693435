import _defaults from 'lodash/defaults'

export default {
    methods: {
        getDeplacementShutters(props = {}) {
            return {
                'deplacement-liste': {
                    name: 'deplacement-liste',
                    title: this.$t('deplacement.transport'),
                    cmpPath: 'components/Mouvements/DeplacementListe',
                },
                'deplacement-form': {
                    name: 'deplacement-form',
                    title: this.$t('deplacement.ajout_transport'),
                    cmpPath: 'components/Mouvements/DeplacementForm', 
                    onOk: this.deplacementFormSubmit,
                    props: props
                },
                'lieu-add-form': {
                    name: 'lieu-add-form',
                    title: this.$t('lieu.ajouter'),
                    cmpPath: 'components/AjoutLieu', 
                    onOk: this.lieuFormSubmit,
                },
                'ajout-tiers-depart': {
                    name: 'ajout-tiers',
                    title: this.$t('tiers.ajouter_tiers'),
                    cmpPath: 'components/ShutterScreens/Tier/FormAdd',
                    onOk: this.setTierDepart
                },
                'ajout-tiers-arrive': {
                    name: 'ajout-tiers',
                    title: this.$t('tiers.ajouter_tiers'),
                    cmpPath: 'components/ShutterScreens/Tier/FormAdd',
                    onOk: this.setTierArrive
                },
                'contact-add-form': {
                    name: 'contact-add-form',
                    title: this.$t('monte.create_contact'),
                    cmpPath: 'components/AjoutContact', 
                    onOk: this.contactFormSubmit,
                },
                'ajout-vehicule': {
                    name: 'ajout-vehicule',
                    title: this.$t('deplacement.ajout_vehicule'),
                    cmpPath: 'components/Mouvements/AddVehicule', 
                    onOk: this.vehiculeSubmit,
                },
                'suggestion-liste': {
                    name: 'suggestion-liste',
                    title: this.$t('deplacement.suggestions'),
                    cmpPath: 'components/Mouvements/SuggestionListe',
                },
                'suggestion-stallion': {
                    name: 'suggestion-stallion',
                    title: this.$t('deplacement.suggestions'),
                    cmpPath: 'components/Mouvements/SuggestionListeStallion',
                },
                'suggestion-mare': {
                    name: 'suggestion-mare',
                    title: this.$t('deplacement.suggestions'),
                    cmpPath: 'components/Mouvements/SuggestionListeMare',
                }
            }
        },

        openDeplacementListe() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['deplacement-liste'])
        },

        openAjoutDeplacement() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['deplacement-form'])
        },

        openEditDeplacement(deplacement) {
            const shutters = this.getDeplacementShutters({deplacement_edit: deplacement})
            this.shutterPanel().open(shutters['deplacement-form'])
        },

		openAjoutLieu() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['lieu-add-form'])
        },

        openAddTierDepart() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['ajout-tiers-depart'])
        },

        openAddTierArrive() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['ajout-tiers-arrive'])
        },

        openAjoutContact() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['contact-add-form'])
        },

        openAjoutVehicule() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['ajout-vehicule'])
        },

        openSuggestionListe() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['suggestion-liste'])
        },

        openAjoutDeplacementFromSuggestion(suggestions, date) {
            const shutters = this.getDeplacementShutters({suggestions, date})
            this.shutterPanel().open(shutters['deplacement-form'])
        },

        openSuggestionStallion() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['suggestion-stallion'])
        },

        openSuggestionMare() {
            const shutters = this.getDeplacementShutters()
            this.shutterPanel().open(shutters['suggestion-mare'])
        }
    }
}
